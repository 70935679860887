<template>
  <v-container class="strategy py-5" fluid style="background: #eee">




<div class="mt-5">
    <v-row class="px-">
      <v-col cols="6" >
        
              <v-select
                label="프리셋 전략 - 롱"
                v-model="preset_buy"
                :items="$store.state.presetOption.buy"
                :item-text="item => item.name"
                :item-value="item => item.id"
                required
                outlined
                dense
                class="mb-2"
                hide-details=""
              ></v-select>

              <keep-alive include="side,symbol,bot_id,index">
                      <WidgetBotOption  :side="'buy'" :symbol="'PRESET'" :bot_id="'preset'+'-buy'"/>
              </keep-alive>
        
      </v-col>
      <v-col cols="6">
              <v-select
                label="글로벌 프리셋 전략 - 숏"
                v-model="preset_sell"
                :items="$store.state.presetOption.sell"
                :item-text="item => item.name"
                :item-value="item => item.id"
                required
                outlined
                dense
                class="mb-2"
                hide-details=""
              ></v-select>



      </v-col>
    </v-row>
</div>



  </v-container>
</template>

<script lang="ts">
import Vue from "vue";
//import HelloWorld from "../components/HelloWorld.vue";

export default Vue.extend({
  name: "Preset",

  components: {
    //HelloWorld,
  },
  data: () => ({
    preset_buy:'PRESET-buy-1',
    preset_sell:'PRESET-sell-1',
  }),
  computed: {},
});
</script>
